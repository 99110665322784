<template>
    <div>
        <h4>Versions</h4>
        <modal
            id="addVersion"
            title="Ajouter une version du materiel"
          >
            <form
              id="add_version"
              class="form-horizontal"
            >
            <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="model">Modèle</label>
                    <select class="form-control" v-select="{placeholder: 'Selectionnez le modèle'}" name="model" id="model" v-model="model">
                        <option/>
                        <option v-for="(m, index) in models" :key="index" :value="m.uid">{{m.name}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="nom">Nom</label>
                    <input type="text" class="form-control" name="nom" id="nom" v-model="nom"/>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="libelle">Libelle</label>
                    <input type="text" class="form-control" name="libelle" id="libelle" v-model="libelle"/>
                  </div>
                </div>
              </div>
              
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="description">Description</label>
                    <textarea
                      name="description"
                      id="description"
                      class="form-control"
                      rows="4"
                      v-model="description"
                    />
                  </div>
                </div>
              </div>
            </form>
            <template slot="footer">
              <a
                href="javascript:void(0)"
                class="btn btn-primary"
                @click="addingVersion"
              > Ajouter</a>
            </template>
          </modal>
        <data-table :dataSource="versions" :headers="headers" table-class="table-striped table-hover">
            <template slot="actions">
              <a href="javascript:void(0)"
                class="btn btn-outline-primary btn-icon"
                @click.prevent="showAddModal"
              >
                <i class="icofont icofont-plus" />
              </a>
            </template>
        </data-table>
    </div>
</template>
<script>
import jQuery from 'jquery'
import {mapGetters, mapMutations} from 'vuex'
import DataTable from '../../components/dataTable/local.vue'
import {COMPONENT_TYPE, DATE_TYPE, TEXT_TYPE} from '../../components/dataTable/dataType'
import Modal from '../../components/modal.vue'
import {ADD_VERSION} from '../../graphql/host'
const $ = jQuery
const Action = () => import('../../components/host/version/listAction.vue')
const Model = () => import('../../components/host/version/model.vue')
export default {
    components: { DataTable, Modal},
    data(){
        return {
            nom: null,
            libelle: null,
            description: null,
            model: null
        }
    },
    methods: {
        ...mapMutations({
            done: 'DONE'
        }),
        showAddModal(){
            $('#addVersion').modal('show')
        },
        addingVersion(){
            this.$apollo.mutate({
                mutation: ADD_VERSION,
                variables: {
                    version: {
                        "name": this.nom,
                        "libelle": this.libelle,
                        "description": this.description,
                        "model": this.model
                    }
                  },
                update: () => {
                    this.nom = null
                    this.libelle = null
                    this.description = null
                    this.done()
				},
            })
        }
    },
    computed: {
        ...mapGetters({
            versions: 'host/versions',
            models: 'host/models'
        }),
        headers(){
            return [
                {label: 'Model', name: 'model', type: COMPONENT_TYPE, component: Model},
                {label: 'Nom', name: 'name', type: TEXT_TYPE, sortable: true},
                {label: 'Version', name: 'libelle', type: TEXT_TYPE, sortable: true},
                {label: 'Recommandé', name: 'recommandedVersion', type: TEXT_TYPE, sortable: true},
                {label: 'Obsolète', name: 'deprecat', type: TEXT_TYPE, sortable: true},
                {label: 'Date Invalid', name: 'dateInvalid', type: DATE_TYPE, sortable: true},
                {label: 'Action', type: COMPONENT_TYPE, component: Action}
            ]
        }
    }
}
</script>